import * as constants from "@/core/config/constant";

// define a mixin object
const mixinSearch = {
  data: function () {
    return {
      loading: false,
      querySearch: {},
      size:constants.LIMIT_PAGINATE,
      page:1,
      form: {

      },
      order_field:null,
      order_by:null,
    }
  },
  computed: {
    queryParam () {
      return this.$route.query;
    }
  },

  methods: {
    getDataSearch(form) {
      let querySearch = this.querySearch;
      Object.keys(form).map(function (value) {
        if (typeof form[value] === 'object' && form[value].join()) {
          querySearch[value] = form[value].join()
        }
        else {  querySearch[value] = form[value] }
      })
      this.querySearch.size = this.queryParam.size ? this.queryParam.size : constants.LIMIT_PAGINATE;
      this.querySearch.page = this.queryParam.page ? this.queryParam.page : 1;
    },
    resetDataSearch() {
      this.querySearch = {}
    },
    onReset(form) {
      // Reset our form values
      Object.keys(form).map(function (value) {
        form[value] = value == "status" ? [] : '';
      })
    },
    sortColumn(routerName,{ prop, order }) {
      let order_by = '';
      switch (order) {
        case 'ascending':
          order_by = 'asc'
          break;
        case 'descending':
          order_by = 'desc'
          break;
        default:
          order_by = '';
          break;
      }
      this.querySearch.order_field = prop;
      this.querySearch.order_by = order_by;
      this.page = 1;
      this.$router.push({
        name: routerName,
        query: {...this.querySearch, ...{size: this.size, page: this.page}}
      });
    },
    search(routerName){
      this.$router.push({
        name: routerName,
        query: {...this.querySearch, page:1}
      });
    },
    sizeChange(routerName,size){
      this.size = size;
      this.page = 1;
      this.$router.push({
        name: routerName,
        query: {...this.querySearch, ...{size: this.size, page: this.page}}
      });
    },
    pageChange(routerName,page){
      this.page = page;
      this.$router.push({
        name: routerName,
        query: {...this.queryParam, ...{size: this.size,page: this.page}}
      });
    },
    showFormSearch(){
      KTUtil.showFormSearch();
    }
  }
}

export default mixinSearch;
