<template>
  <button class="button-search-mobile" @click="showFormSearch()">
    <img :src="iconOpenSearch">
  </button>
</template>

<script>
import mixinSearch from "@/mixins/searchMixin";

export default {
name: "ButtonSearchMobile",
  mixins: [mixinSearch],
  computed: {
    iconOpenSearch(){
      return process.env.BASE_URL + 'img/search.png';
    }
  }
}
</script>

<style scoped>

</style>
