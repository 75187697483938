<template>
  <div v-loading="loading">
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header">
        <div class="card-title">
          <span class="card-icon">
              <i class="flaticon2-delivery-package text-primary"></i>
          </span>
          <h3 class="card-label"><b class="title-header">フォーム検索</b></h3>
        </div>
        <div>
          <ButtonSearchMobile></ButtonSearchMobile>
        </div>
      </div>
      <div class="card-body">
        <div id="search-form">
          <form  @submit.prevent="onSubmit" @keyup.enter="onSubmit">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-4 col-6">
                    <div class="form-group">
                      <label>フォーム名</label>
                      <input name="form_name" placeholder="フォーム名を入力" v-model="form.form_name" type="text" class="form-control"/>
                    </div>
                  </div>
                  <div class="col-md-4 col-6">
                    <div class="form-group">
                      <label>項目名フリーワード</label>
                      <input name="label_name" placeholder="項目名フリーワードを入力" v-model="form.label_name" type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-4 col-6">
                    <div class="form-group">
                      <label>回答内容フリーワード</label>
                      <input name="answer_value" placeholder="回答内容フリーワードを入力" v-model="form.answer_value" type="text" class="form-control"/>
                    </div>
                  </div>
<!--                    phase 2-->
                  <div class="col-md-4 col-6">
                    <div class="form-group">
                      <label>親フォーム</label>
                      <el-select
                          multiple
                          style="width: 100%"
                          v-model="form.parent_form_id"
                      >
                        <el-option
                        v-for="item in listFormName"
                            :key="item.form_id"
                            :label="item.form_name"
                            :value="item.form_id"
                        >
                         </el-option>
                      </el-select>
                    </div>
                  </div>
                    <div class="col-md-4 col-6">
                        <div class="form-group">
                            <label>フォームステータス</label>
                            <el-select
                                multiple
                                style="width: 100%"
                                v-model="form.form_status"
                            >
                                <el-option v-for="status in formStatus" :value="status.value" :label="status.label" :key="status.value">{{ status.label }}</el-option>
                            </el-select>
                        </div>
                    </div>
<!--                    phase 2-->
                  <div class="col-6 text-right">
                    <div class="button-submit-mobile">
                      <button type="submit" class="btn btn-primary mr-2">{{$t('search')}}</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group action-form-search">
                  <button type="submit" class="btn btn-primary mr-2 button-form-search">{{$t('search')}}</button>
                  <button @click="exportExcel"
                   type="button" class="btn btn-primary mr-2"
                    :disabled="formBaseList.total <= 0"
                    >{{$t('export_csv')}}</button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div id="data-table-list">
          <div class="header-table-mobile">
            <button @click="exportExcel"
             type="button" class="btn btn-primary mr-2"
            :disabled="formBaseList.total <= 0"
             >{{$t('export_csv')}}</button>
          </div>
          <div class="row float-right pr-5">
            <my-pagination
                v-if="formBaseList.total"
                :total-record="parseInt(formBaseList.total)"
                :current-page="parseInt(formBaseList.current_page)"
                :page-size-default="parseInt(formBaseList.per_page)"
                @handleCurrentChange="handleCurrentChange"
                @handleSizeChange="handleSizeChange"
            />
          </div>
          <el-table
            v-if="formBaseList.data && formBaseList.total > 0"
              class="mb-5"
              :data="formBaseList.data"
              style="width: 100%"
              @sort-change="sortChange"
          >
            <el-table-column
                prop="form_name"
                label="フォーム名"
                min-width="200"
                sortable="false">
                <template slot-scope="scope">
                <span v-if="hasPermissonCheck('form_flg')">
                    <router-link :to="{ name: 'edit-form', params: { form_id:  scope.row.form_id }}">{{ scope.row.form_name }}</router-link>
                 </span>
                 <span v-else>
                    <a class="text-primary cursor-pointer" @click="not_permisson_popup = true">{{ scope.row.form_name }}</a>
                 </span>
              </template>
            </el-table-column>
            <el-table-column
                prop="form_items_count"
                label="項目数"
                min-width="200"
                sortable="false">
            </el-table-column>
            <el-table-column
                prop="form_url"
                label="フォームURL"
                min-width="200"
                sortable="false">
              <template slot-scope="scope">
                <span>
                <a target="_blank" :href="scope.row.user_url">
                   {{ scope.row.form_url }}
                </a>
                 </span>
              </template>
            </el-table-column>
<!--              phase 2-->
            <el-table-column
                prop="parent_form_id"
                label="親フォーム"
                min-width="200"
                sortable="false">
                <template slot-scope="scope">
                <div v-if="scope.row.parent_form_name">
                    <span v-if="scope.row.parent_delete_flag === form_base_delete_flag.ACTIVE">
                           <span v-if="hasPermissonCheck('form_flg')">
                                <router-link :to="{ name: 'edit-form', params: { form_id:  scope.row.parent_form_id }}">{{ scope.row.parent_form_name }}</router-link>
                           </span>
                           <span v-else>
                                <a class="text-primary cursor-pointer" @click="not_permisson_popup = true">{{ scope.row.parent_form_name }}</a>
                            </span>

                    </span>
                    <span v-else-if="scope.row.parent_delete_flag === form_base_delete_flag.DELETE">
                        {{ scope.row.parent_form_name }}
                    </span>
                 </div>
              </template>
            </el-table-column>
            <el-table-column
                prop="countParentForm"
                label="子フォーム数"
                min-width="200"
                >
            </el-table-column>
            <el-table-column
                label="テナント名"
                min-width="200"
                >
              <template slot-scope="scope">
                <span v-if="scope.row.tenant">
                  <a target="_blank" :href="scope.row.tenant.admin_url">
                    {{scope.row.tenant.tenant_name}}
                  </a>
                </span>
              </template>
            </el-table-column>
<!--              phase 2-->
            <el-table-column
                min-width="460"
            >
              <template slot-scope="scope">
                <div class="text-right" v-if="hasPermissonCheck('form_flg')">

                    <router-link
                        class="btn btn-primary mr-2"
                        :to="{ name: 'form-item-add', query: { form_id:  scope.row.form_id }}"
                    >
                        {{$t('item_setting')}}
                    </router-link>
                    <button
                        @click="confirmDelete(scope.row)"
                        class="btn btn-danger mr-2">
                        削除
                    </button>
                    <button
                        @click="dialogConfirmCopy(scope.row)"
                        class="btn btn-primary mr-2">
                        コピー
                    </button>
                    <button
                        @click="downloadFormStructure(scope.row.form_id,scope.row.form_name)"
                        class="btn btn-primary mr-2">
                        項目設定内容CSVダウンロード
                    </button>
                </div>
                <div class="text-right" v-else>
                    <a class="btn btn-primary mr-2" @click="not_permisson_popup = true">
                        {{$t('item_setting')}}
                    </a>
                    <button
                        @click="not_permisson_popup = true"
                        class="btn btn-danger mr-2">
                        削除
                    </button>
                    <button
                        @click="not_permisson_popup = true"
                        class="btn btn-primary mr-2">
                        コピー
                    </button>
                    <button
                        @click="not_permisson_popup = true"
                        class="btn btn-primary mr-2">
                        項目設定内容CSVダウンロード
                    </button>
                </div>
            </template>
            </el-table-column>
          </el-table>
          <div v-else class="text-center no-list-data">{{$t('el.table.emptyText')}}</div>
          <div class="row float-right pr-5">
            <my-pagination
                v-if="formBaseList.total"
                :total-record="parseInt(formBaseList.total)"
                :current-page="parseInt(formBaseList.current_page)"
                :page-size-default="parseInt(formBaseList.per_page)"
                @handleCurrentChange="handleCurrentChange"
                @handleSizeChange="handleSizeChange"
            />
          </div>
        </div>

      </div>
    </div>
      <Popup title="確認" id="confirm-delete" :content=content_delete_form title_button="いいえ">
          <b-button @click.prevent="deleteFormBase" class="btn-primary">{{ $t("YES") }}</b-button>
      </Popup>

      <Popup title="確認" id="confirm-coppy" :content="form_base_copy.form_name + 'をコピーしますか？'" title_button="いいえ">
          <b-button @click.prevent="coppyFormBase" class="btn-primary">{{ $t("YES") }}</b-button>
      </Popup>
      <my-dialog v-on:closeDialog="not_permisson_popup=false" v-on:submit="not_permisson_popup=false;$router.push({name: 'permission-denied'});" v-bind:dialog="not_permisson_popup"
                 :closeOnClickModal="false" width="28%" v-bind:btnOk="$t('DIALOG.BTN_OKIE_PERMISSON_DENIED')" :showCancelButton="false">
          <template slot="header">
              <div class="textwidget text-center">
                  {{ $t('DIALOG.TITLE_PERMISSON_DENIED') }}
              </div>
          </template>
      </my-dialog>
  </div>
</template>

<script>
import mixinSearch from "@/mixins/searchMixin";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {
  GET_LIST_FORM_BASE,
  EXPORT_FORM_BASE_FILE_CSV,
  GET_LIST_FORM_NAME,
    DELETE_FORM_BASE_BY_ID,
    CLONE_FORM_BASE,
    EXPORT_FORM_STRUCTURE,
} from "@/core/services/store/formBase.module";
import ButtonSearchMobile from "@/view/components/ButtonSearchMobile";
import {ENV} from "@/core/config/env";
import Popup from "@/view/components/Popup";
import * as constants from "@/core/config/constant";

export default {
  name: "list",
  components: {ButtonSearchMobile,Popup},
  mixins: [mixinSearch],
  data() {
    return {
      loading:false,
      formStatus: constants.FORM_BASE_STATUS,
      form:{
        form_name:'',
        label_name:'',
        answer_value:'',
        parent_form_id: [],
        form_status: [],
      },
      base_url: ENV.BASE_URL,
        form_base_delete_id:0,
        form_base_delete_flag: constants.FORM_BASE_DELETE_FLAG,
        content_delete_form: '',
        form_base_copy: '',
      not_permisson_popup:false
    }
  },
  computed: {
    ...mapGetters([
      'formBaseList', 'listFormName'
    ]),
    queryParam() {
      return this.$route.query
    },
  },
  watch: {
    async queryParam() {
      this.loading = true;
        if (Object.keys(this.queryParam).length === 0){
            this.resetDataSearch();
        }
      await this.getListFormBase(this.queryParam);
      await this.getListFormName();
       this.getDataFromQuery();
      this.loading = false;
    }
  },
  async created() {
    if (this.hasPermissonCheck('form_flg')){
      this.loading = true;
      this.getDataFromQuery();
      await this.getListFormBase(this.queryParam);
      await this.getListFormName();
      this.loading = false;
    } else {
      this.not_permisson_popup = true
    }
  },
  methods: {
    ...mapActions([GET_LIST_FORM_BASE,
        EXPORT_FORM_BASE_FILE_CSV,
        GET_LIST_FORM_NAME,
        DELETE_FORM_BASE_BY_ID,
        CLONE_FORM_BASE,
        EXPORT_FORM_STRUCTURE
    ]),
    sortChange(sortProps){
      this.sortColumn('form-base-list',sortProps);
    },
    getDataFromQuery(){
      var self = this;
      this.form.form_name = this.queryParam.form_name ? this.queryParam.form_name : '';
      this.form.label_name = this.queryParam.label_name ? this.queryParam.label_name : '';
      this.form.answer_value = this.queryParam.answer_value ? this.queryParam.answer_value : '';
      var arrayParentForm = (this.queryParam.parent_form_id && this.queryParam.parent_form_id.length) ? this.queryParam.parent_form_id.split(',') : [];
      this.form.parent_form_id = arrayParentForm.map(item => parseInt(item));
      var arrayStatus = (this.queryParam.form_status  && this.queryParam.form_status.length )? this.queryParam.form_status.split(',') : [];
      this.form.form_status = arrayStatus.map(item => parseInt(item));
    },
    onSubmit(){
      this.form.form_name=this.form.form_name.trim()
      this.form.label_name=this.form.label_name.trim()
      this.form.answer_value=this.form.answer_value.trim()
      this.getDataSearch(this.form);
      this.search("form-base-list")
    },
    handleSizeChange(val) {
      this.sizeChange('form-base-list',val);
    },
    handleCurrentChange(val) {
      this.pageChange('form-base-list',val);
    },
    async exportExcel(){
        if (this.hasPermissonCheck('form_flg')) {
            let data = await this.exportFormBaseFileCsv(this.queryParam);
            this.exportCsvCommon('SC005_', data)
        } else {
            this.not_permisson_popup = true
        }
    },
      async deleteFormBase(){
          this.loading = true;
          let data = await this.deleteFormBaseById(this.form_base_delete_id);
          if (data){
              this.notifySuccess(this.$t("DELETE_SUCCESS"));
              this.getDataFromQuery();
              await this.getListFormBase(this.queryParam);
              await this.getListFormName();
              this.$bvModal.hide("confirm-delete");
          }
          this.loading = false;
      },
      confirmDelete($form){
          this.content_delete_form = this.$t("CONFIRM_DELETE" , {form_name: $form.form_name});
          this.form_base_delete_id = $form.form_id;
          this.$bvModal.show("confirm-delete");
      },
      dialogConfirmCopy(formBase) {
        this.form_base_copy = formBase
        this.$bvModal.show("confirm-coppy");

      },
       coppyFormBase() {
          this.loading = true
          this.cloneFormBase(this.form_base_copy.form_id).then( respon => {
            this.loading = false
            if (respon.code == constants.STATUS_CODE.SUCCESS) {
                 this.getListFormBase(this.queryParam);
                 this.getListFormName();
                this.notifySuccess(this.$t("COPY_SUCCESS"))
            }
          }).catch(e => {
              this.loading = false
              this.notifyError(e)
          })
          this.$bvModal.hide("confirm-coppy");


      },
      async downloadFormStructure($formId,$formName){
          let data = await this.exportFormStructure($formId);
          this.exportCsvCommon($formName, data)
      }

  },

}
</script>

<style scoped>

</style>
